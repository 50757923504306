body {
  color: #fff;
  /* background-color: #fff; */
  background-image: url("./assets/img/bg.jpeg");
}

html {
  min-height: 100%;
}

.main {
  height: 100vh;
}

.logodiv {
  height: 20vh;
}

.anidiv {
  height: 50vh;
}

.footerdiv {
  height: 30vh;
}

.logo {
  height: 250px;
}

.mih1 {
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.subtitle {
  font-size: 1.3rem;
  letter-spacing: 3px;
}

.plus-img {
  height: 60px;
  cursor: pointer;
  border-radius: 1rem;
}

.si {
  height: 50px;
  margin: 0 10px;
}

.cusbtn {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  max-width: 360px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.cusbtn:hover {
  color: #000;
}

.mintbutton {
  background-color: #fff;
  color: #fff !important;
  letter-spacing: 3px;
  font-size: 1rem;
  border: 2px solid #fff;
}

.mint {
  margin-top: 6rem;
  background-color: #161f238c;
  padding: 3rem;
  border-radius: 1.5rem;
}

.plus-img {
  height: 60px;
  cursor: pointer;
  border-radius: 1rem;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #fff;
  /* background-color: #fff; */
  height: 70px;
  width: 70px;
  border-radius: 4px;
}
.number {
  font-weight: bold;
  font-size: 3.3rem;
}

.cusbg {
  background-color: rgba(0, 0, 0, 0.671);
  padding: 3rem;
  border-radius: 3rem;
}

@media only screen and (max-width: 600px) {
  .rmt {
    margin-top: 1rem;
  }

  .main {
    margin: 0 1rem;
  }
  /* 
  .logodiv {
    height: auto;
  }

  .anidiv {
    height: auto;
  }

  .footerdiv {
    height: auto;
  } */

  .fw {
    width: 100% !important;
  }
}
